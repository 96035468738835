<template>
  <div class="app-container">
    <navBar :title="'补卡申请'" @handleGoBack="handleGoBack">
      <template v-slot:icon2>
        <i class="iconfont icon-gengduo" style="font-size: 20px"></i>
      </template>
    </navBar>
    <div class="add-form">
      <van-form validate-first @failed="onFailed" @submit="onSubmit">
        <div class="line"></div>
        <van-field
            readonly
            clickable
            name="clockTime"
            :value="formInline.clockTime"
            label="补卡时间"
            placeholder="点击选择日期"
            @click="showCalendar = true"
            :rules="[{ required: true, message: '请选择时间' }]"
        />
        <van-popup v-model="showCalendar" position="bottom" :style="{ height: '30%' }">
          <van-datetime-picker
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              type="datetime"
              title="选择完整时间"
              @confirm="onConfirm"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            name="workTypeName"
            v-model="formInline.workTypeName"
            label="打卡类型"
            placeholder="请选择"
            @click="showType = true"
            :rules="[{ required: true, message: '请选择打卡类型' }]"
        />
        <van-popup v-model="showType" position="bottom">
          <van-picker
              show-toolbar
              :columns="typeColumns"
              @confirm="onTypeConfirm"
              @cancel="showType = false"
          />
        </van-popup>

        <textAreaInput
            :title="'补卡理由'"
            :remark="formInline.remark"
            @change="handleRemarkChange"
        />

        <div class="line"></div>
        <van-field name="uploader" label="附件" v-if="isShowAuth">
          <template #input>
            <van-uploader
                class="mrg-t-10"
                capture="camera"
                v-model="uploader"
                :after-read="afterRead"
            />
          </template>
        </van-field>
        <van-button class="btn" block type="info" native-type="submit" :disabled="isDisabled">提交</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
import {changeToCurrentDay} from '@/utils/ruoyi';
import {checkwork} from '@/api/hr/hrAttendance';
import {submiteUpload} from '@/api/system/config';

export default {
  data() {
    return {
      formInline: {
        clockType: 2, // 打卡类型 0上班打卡;1下班打卡;2补卡
        clockTime: null,
        remark: null,
        workType: null,
        workTypeName: null,
      },
      currentDate: new Date(),
      minDate: new Date(2022, 1, 1),
      maxDate: new Date(2035, 10, 1),
      showCalendar: false,
      uploader: [],
      typeColumns: ['坐班', '外勤'],
      showType: false,
      file: null,
      isDisabled: false,
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  methods: {
    onConfirm(value) {
      this.formInline.clockTime = changeToCurrentDay(value);
      this.showCalendar = false;
    },

    onTypeConfirm(value) {
      this.formInline.workType = value == '坐班' ? 0 : 1;
      this.formInline.workTypeName = value;
      this.showType = false;
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    async onSubmit() {
      this.isDisabled = true
      let centerPoint = localStorage.getItem('centerPoint')
      if (centerPoint) {
        centerPoint = JSON.parse(centerPoint)
        this.formInline.clockLonLat = centerPoint.clockLonLat
        this.formInline.clockAddress = centerPoint.address
      }

      const params = this.objectToFormData(this.formInline);
      const result = await checkwork(params);
      if (result.code == 200) {
        this.$toast('补卡申请已提交成功');

        if (this.file) {
          let params = new FormData();
          params.append('file', this.file);
          params.append('businessType', 'hr_checkwork');
          params.append('businessId', result.data);
          await submiteUpload(params);
        }
        this.handleGoBack();
        this.isDisabled = false
      }
    },

    handleGoBack() {
      this.$router.replace('/attendance');
      this.$router.go(-1)
    },

    handleRemarkChange(val) {
      this.formInline.remark = val;
    },

    afterRead(file) {
      this.file = file.file;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

::v-deep .van-picker__toolbar {
  height: 70px;
}

::v-deep .van-picker__title,
::v-deep .van-picker__cancel,
::v-deep .van-picker__confirm {
  height: 40px;
  line-height: 40px;
  font-size: 28px;
}

::v-deep .van-picker-column {
  font-size: 30px;
}
</style>
